/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
exports.onInitialClientRender = () => {
  const name = 'Hire';
  const domain = 'RickDunk';
  const tld = 'in';
  console.log(`%cHello! If you would like to contact me, you can reach me via the email address below.\n\n${name}@${domain}.${tld}`, 'font-size: large; color: teal;');
};
